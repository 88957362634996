.display-flex {
    display: flex;
}

.display-block {
    display: block;
}

.project-name {
    font-size: 20px;
    font-weight: bolder;
}

.is-urgent {
    color: red;
}

.issue-additional-text {
    font-size: 12px;
}

.data-row {
    display: flex;
    align-items: center;
}

.data-row-assignee {
    white-space: nowrap;
}

.project-tasks-unassigned {
    color: red;
}

.project-tasks-in-progress {
    color: orange;
}

.project-tasks-todo {
    color: darkblue;
}

.project-tasks-paused {
    color: darkslateblue;
}

.project-tasks-done {
    color: green;
}

.project-tasks-comparisons-count {
    color: darkslateblue;
}

.tasks-info {
    font-size: 13px;
    font-weight: bolder;
}

.project-public {
    text-align: center;
}
.project-not-answered-cmp {
    text-align: center;
}

.project-configuration-dropdown .button-icon {
    color: var(--main-theme-color);
}

.dropdown-options .remove-last-wave {
    color: red;
}

.dropdown-options .remove-last-wave .button-icon {
    color: red;
}

.project-configuration-dropdown .dropdown-options .button-icon {
    color: black;
    align-items: center;
}

.single-project-box-disabled {
    opacity: 0.3;
    pointer-events: none;
}

.single-project-box-disabled-message {
    margin: 0;
    padding: 5px 25px;
    border-radius: 20px;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    display: block;
    position: absolute;
    color: red;
    font-size: 25px;
    font-weight: bolder;
    background-color: #d9d9d9;
    z-index: 1;
    opacity: 0.8;
}

.single-project-box .project-accordion-toggle {
    color: black;
    opacity: 0.17;
    box-shadow: none !important;
    padding: 0;
    margin-left: 20px;
}

.disabled-configuration-link {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
    filter: grayscale(100%);
}

.select-validation-error {
    font-size: 12px;
    color: rgb(244, 67, 54);
}
