:root {
    --dark: #1b1e24;
    --translate-scale: 2.2;
    /* Enables progress manipulation with JS | TaskProgressBar() */
    --progress-bar-completion: 0%;
}

.comparison-wrapper {
    display: grid;
    grid-template-columns: 11% 24% auto 51% 11%;
    position: relative;
    top: 32px;
    margin: auto auto 64px;
    align-content: start;
}

.comparison-wrapper .reference-wrapper {
    position: fixed;
    left: 11%;
    width: 24%;
    grid-column-start: 2;
    grid-column-end: 2;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    height: fit-content;
    padding: 16px 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.comparison-wrapper .gap-wrapper {
    grid-column-start: 3;
    grid-column-end: 3;
}

.comparison-wrapper .parsed-img-wrapper {
    grid-column-start: 4;
    grid-column-end: 4;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: 32% 32% 32%;
    gap: 2%;
    padding: 20px 2%;

    position: fixed;
    width: 51%;
    right: 11%;
    overflow-y: scroll;
    overflow-x: hidden;
    flex-direction: row;
    align-content: flex-start;

    top: 160px;
    bottom: 30px;
    /* 100%  - top offset - bottom offset*/
    height: calc(100% - 160px - 30px);
}

.header-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: space-between;
}

.header-wrapper div {
    width: 100%;
    align-items: center;
    display: inherit;
}

.header-wrapper div:nth-child(1) {
    justify-content: left;
}

.header-wrapper div:nth-child(2) {
    justify-content: center;
}

.header-wrapper div:nth-child(3) {
    justify-content: right;
}

.header-wrapper div .task-progress-bar-wrapper {
    width: 82%;
    height: 40%;
    border-radius: 16px;
    border: solid 1px #d4d4d4;
}

.header-wrapper div .task-progress-bar {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
    background: #5fb230
        linear-gradient(
            90deg,
            #5fb230 0%,
            #5fb230 var(--progress-bar-completion),
            #f0f0f0 var(--progress-bar-completion),
            #f0f0f0 100%
        );
}

.header-wrapper div .info-buttons {
    justify-content: right;
    width: 100%;
}

.undo-button {
    width: fit-content;
    border-radius: 4px;
    background-image: linear-gradient(to right, #70b9da 0%, #008dc2 100%);
}
.leave-task-button {
    width: fit-content;
    border-radius: 4px;
    background-image: linear-gradient(to right, #b67174 0%, #c2002d 100%);
}

.info-buttons .inverted-button {
    border-radius: 4px;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--dark);
    background-image: none;
}

.bottom-grid-buttons .info-buttons .inverted-button {
    padding: 17px;
    box-shadow: 0px 0px 1px 0px gray;
}

.info-buttons .inverted-button:hover {
    box-shadow: 0 1px 4px -1px #333;
    transition: all 0.1s ease-in-out;
}

.info-buttons .inverted-button.disabled:hover {
    box-shadow: 0px 0px 1px 0px gray;
    transition: unset;
}

.info-buttons .inverted-button svg {
    color: #008dc2;
}

.header-wrapper .btn-primary {
    padding: 11px 17px;
    margin: 0 5px;
    height: 50%;
}

.global-header-override {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    width: fit-content;
    height: 64px;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 3px;
}

.global-header-override .project-header,
.global-header-override .shop-header {
    width: 100%;
    display: inherit;
    justify-content: center;
}

.global-header-override .project-header {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: var(--dark);
}

.global-header-override .shop-header {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: var(--dark);
}

.reference-img {
    max-width: 100%;
    max-height: 60vh;

    cursor: pointer;
}

.reference-header {
    font-family: Lato;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: var(--dark);
    width: 100%;
}

.grid-item {
    margin-bottom: 16px;
    margin-left: unset;
    margin-right: unset;
    border-radius: 4px;
    border: solid 1px #f0f0f0;
    background-color: #ffffff;
    display: inherit;
    justify-content: center;
    max-height: 180px;
    z-index: 1;
    width: 100%;
}

.parsed-img-wrapper:last-child:not("bottom-grid-buttons") {
    margin-left: 0;
}

.grid-item img {
    max-width: 100%;
    max-height: 164px;
    padding: 12px;
}

.grid-items-wrapper {
    width: 100%;
    display: grid;
    grid-auto-columns: auto;
    grid-template-columns: repeat(auto-fill, minmax(100px, 250px));
    justify-content: space-between;
    min-height: calc(100% - 100px);
}

.top-grid-buttons,
.bottom-grid-buttons {
    grid-column-start: 1;
    grid-column-end: 4;
    margin-bottom: 23px;
    display: inherit;
    justify-content: right;
    width: 100%;
    height: fit-content;
}

.comparison-wrapper .custom-checkbox .custom-control-label {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark);
    padding-left: 11px;
}

.comparison-wrapper .custom-checkbox .custom-control-label::before {
    border-radius: 2px;
    border: solid 1px #8a8f97;
    background: none;
    z-index: -1;
    top: 1px;
    width: 18px;
    height: 18px;
}

.comparison-wrapper
    .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::after {
    background-image: none;
    background-color: #008dc2;
    z-index: -1;
    border-radius: 1px;
    width: 12px;
    height: 12px;
    margin: 3px;
    top: 1px;
}

.bottom-grid-buttons div {
    display: inherit;
    grid-template-columns: auto auto;
    max-height: 32px;
}

.bottom-grid-buttons div .btn-primary {
    margin: 0 5px;
    padding-left: 17px;
    padding-right: 17px;
}

.bottom-grid-buttons .warning-button svg {
    color: #e7bb00;
}

.bottom-grid-buttons .danger-button svg {
    color: darkred;
}

.next-button.disabled {
    border-radius: 4px;
    background-color: #f0f0f0;
    cursor: default;
}

.bottom-grid-buttons .next-button.disabled svg {
    color: grey;
}

.bottom-grid-buttons .next-button svg {
    color: #5fb230;
}

.all-next-button.disabled {
    border-radius: 4px;
    background-color: #f0f0f0;
    cursor: default;
}

.bottom-grid-buttons .all-next-button.disabled svg {
    color: grey;
}

.bottom-grid-buttons .all-next-button svg {
    color: #0c8dfd;
}

.grid-item-clone {
    align-content: center;
    display: grid;
    position: absolute;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
    border: solid 1px #f0f0f0;
    background-color: #ffffff;
    transform: scale(var(--translate-scale));
    z-index: 2;
}

.grid-item-clone.active-grid-item,
.parsed-img-wrapper .grid-item.active-grid-item,
.parsed-img-wrapper .grid-item.active-grid-item:hover {
    border: solid 2px #008dc2;
    margin-bottom: 14px;
}

.grid-item-wrapper {
    display: inherit;
}

.bottom-grid-buttons {
    margin-bottom: unset;
    margin-top: auto;

    width: fit-content;
    width: -moz-fit-content;
    margin-left: auto;
}
