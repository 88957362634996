.main {
    min-height: 600px;
    margin: 0 auto;
    width: auto;
    max-width: 460px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.path {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    animation: dash 3s alternate infinite;
}

.fundo {
    animation: scales 2.25s alternate infinite;
    transform-origin: center;
}

.pao-baixo {
    animation: rotatepao 10.5s cubic-bezier(0.1, 0.49, 0.41, 0.97) infinite;
    transform-origin: center;
}

.pao-cima {
    animation: rotatepao 5.25s 0.75s cubic-bezier(0.1, 0.49, 0.41, 0.97)
        infinite;
    transform-origin: center;
}

.olhos {
    animation: olhos 1.5s alternate infinite;
    transform-origin: center;
}

.left-sparks {
    animation: left-sparks 3s alternate infinite;
    transform-origin: 150px 156px;
}

.right-sparks {
    animation: left-sparks 3s alternate infinite;
    transform-origin: 310px 150px;
}

.olhos {
    animation: olhos 1.5s alternate infinite;
    transform-origin: center;
}

@keyframes scales {
    from {
        transform: scale(0.9);
    }
    to {
        transform: scale(1);
    }
}

@keyframes rotatepao {
    0% {
        transform: rotate(0deg);
    }
    50%,
    60% {
        transform: rotate(-20deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes olhos {
    0% {
        transform: rotateX(0deg);
    }
    100% {
        transform: rotateX(30deg);
    }
}

@keyframes left-sparks {
    0% {
        opacity: 0;
    }
}

@keyframes dash {
    0%,
    30% {
        fill: #4b4b62;
        stroke-dashoffset: 0;
    }
    80%,
    100% {
        fill: transparent;
        stroke-dashoffset: -200;
    }
}
