.btn {
    display: flex !important;
    align-items: center !important;
}

.btn:hover {
    box-shadow: 0 1px 4px -1px #333;
    transition: all 0.1s ease-in-out;
}

.button-icon {
    margin-right: 8px;
}
.btn-outline-primary .button-icon {
    color: var(--main-theme-color);
}

.btn-outline-primary:hover .button-icon {
    color: white;
}

.btn-primary {
    background-image: linear-gradient(
        rgb(109, 196, 215),
        var(--main-theme-color)
    );
    border: 0 !important;
}

.btn-outline-primary {
    border: 1px solid var(--main-theme-color) !important;
    color: black !important;
}

.btn-outline-primary:hover {
    background-color: unset;
    background-image: linear-gradient(
        rgb(109, 196, 215),
        var(--main-theme-color)
    );
    color: white !important;
    box-shadow: 0 2px 5px -1px #333;
}
