/* Root variables can be used in other CSS files. */
/* Example:                                       */
/*    background-color: vars(--main-theme-color); */
:root {
    --main-theme-color: rgb(34, 124, 168);
    --body-background-color: #eee;
}
/* ---------------------------------------------- */

.p30 {
    padding: 30px !important;
}

.pt64 {
    padding-top: 64px !important;
}

.pr0 {
    padding-right: 0 !important;
}

.pb30 {
    padding-bottom: 30px !important;
}

.pr10 {
    padding-right: 10px !important;
}

.pr30 {
    padding-right: 30px !important;
}

.pl0 {
    padding-left: 0 !important;
}

.pl10 {
    padding-left: 10px !important;
}

.pl30 {
    padding-left: 30px !important;
}

.m30 {
    margin: 30px !important;
}

.mb0 {
    margin-bottom: 0 !important;
}
