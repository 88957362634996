.single-task-card {
    min-height: 180px;
    /*width: 26vw;*/
    display: grid;
    grid-template-columns: repeat(auto-fill, 230px);
    grid-gap: 1rem;
    justify-content: space-between;
    padding: 1rem;
    margin-left: 3rem;
}

.single-task-card-info {
    font-size: 10px;
}

.single-task-take-it-btn {
    display: flex;
    justify-content: center;
}

/* Card title takes the same amount of space when title consists of only 1 row. */
.single-task-display-name {
    min-height: 2.8em;
}

.single-task-display-name p {
    margin-bottom: 0;
}
/* Card background white - workaround. */
.task-card {
    background-color: white;
}
