@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
    background-color: var(--body-background-color);
    font-family: "Lato", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.main-container {
    margin: 50px auto;
    width: 960px;
}

.container-white {
    background: white;
    border-radius: 4px;
    padding: 35px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
    margin-bottom: 10px;
}

.white-box {
    background: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 35px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
    margin-bottom: 10px;
}

.urgent-box {
    background: lightgoldenrodyellow;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 35px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
    margin-bottom: 10px;
}

.white-box-list {
    padding: 10px 35px;
}

.white-box-list-nested {
    margin-left: 50px;
    margin-right: 50px;
}

.data-row {
    display: flex;
    align-items: center;
}

/* For red asterisk if required*/
label.required::before {
    content: "* ";
    color: #d72c2c;
}

/* All custom checkboxes with gradient background */
.custom-control-input:checked ~ .custom-control-label::before {
    background: linear-gradient(rgb(109, 196, 215), var(--main-theme-color));
    border: none;
}

/* SweetAlert modal styles */
.swal2-shown.swal2-confirm {
    background-image: linear-gradient(
        rgb(109, 196, 215),
        var(--main-theme-color)
    );
    border: 0;
}

.swal2-shown .swal2-confirm:hover {
    box-shadow: 0 1px 4px -1px #333;
    transition: all 0.1s ease-in-out;
}

.swal2-shown [type="button"] {
    padding: 7px 19px;
    border-radius: 2px;
    border: 0;
}

.search-box input::-webkit-outer-spin-button,
.search-box input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.search-box input[type="number"] {
    -moz-appearance: textfield !important;
}


/* Root variables can be used in other CSS files. */
/* Example:                                       */
/*    background-color: vars(--main-theme-color); */
:root {
    --main-theme-color: rgb(34, 124, 168);
    --body-background-color: #eee;
}
/* ---------------------------------------------- */

.p30 {
    padding: 30px !important;
}

.pt64 {
    padding-top: 64px !important;
}

.pr0 {
    padding-right: 0 !important;
}

.pb30 {
    padding-bottom: 30px !important;
}

.pr10 {
    padding-right: 10px !important;
}

.pr30 {
    padding-right: 30px !important;
}

.pl0 {
    padding-left: 0 !important;
}

.pl10 {
    padding-left: 10px !important;
}

.pl30 {
    padding-left: 30px !important;
}

.m30 {
    margin: 30px !important;
}

.mb0 {
    margin-bottom: 0 !important;
}

#header-global {
    height: 64px;
    display: flex;
    align-items: center;
}

.epack-logo {
    max-height: 64px;
    /*border: 1px solid red; !*TODO: For testing*!*/
}

.main-navbar ul.nav li {
    margin: 0 0 0 32px;
}
.main-navbar ul.nav li a {
    /*border: 1px solid red; !*TODO: For testing*!*/
    padding: 6px 0;
    font-size: 16px;
    color: #1b1e24;
    text-decoration: none;
    border-bottom: 2px solid #ffffff;
    transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out,
        border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

.main-navbar ul.nav li a:hover {
    border-bottom: 2px solid #e2e6ea;
}

.navbar-active {
    border-bottom: 2px solid var(--main-theme-color) !important;
}

.user-dropdown {
    margin-left: auto;
}

.header-container {
    width: 100%;
    min-width: 980px;
    background: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    position: fixed;
    padding: 0 16px;
    z-index: 100;
}

.user-control-buttons {
    display: flex;
}

.user-name {
    font-size: 16px;
    font-weight: bolder;
}

.user-email,
.user-create-date {
    font-size: 12px;
}

#header-local {
    height: 64px;
    display: flex;
    align-items: center;
    border-top: 1px solid #f0f0f0;
}

.btn {
    display: flex !important;
    align-items: center !important;
}

.btn:hover {
    box-shadow: 0 1px 4px -1px #333;
    transition: all 0.1s ease-in-out;
}

.button-icon {
    margin-right: 8px;
}
.btn-outline-primary .button-icon {
    color: var(--main-theme-color);
}

.btn-outline-primary:hover .button-icon {
    color: white;
}

.btn-primary {
    background-image: linear-gradient(
        rgb(109, 196, 215),
        var(--main-theme-color)
    );
    border: 0 !important;
}

.btn-outline-primary {
    border: 1px solid var(--main-theme-color) !important;
    color: black !important;
}

.btn-outline-primary:hover {
    background-color: unset;
    background-image: linear-gradient(
        rgb(109, 196, 215),
        var(--main-theme-color)
    );
    color: white !important;
    box-shadow: 0 2px 5px -1px #333;
}

.search-box {
    margin-left: auto;
    margin-right: 2px;
}

.search-box button {
    border-radius: 0 4px 4px 0;
    padding-left: 20px;
    padding-right: 20px;
}

.search-box input {
    width: 250px !important;
    border-radius: 4px 0 0 4px !important;
    padding-left: 25px;
}

.search-box .search-icon {
    color: lightgrey;
    position: relative;
    right: -22px;
    top: 9px;
    z-index: 9999;
}

.loading-text-centered {
    margin: 0;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    display: block;
    position: absolute;
    color: #57aec7;
    font-size: 25px;
    font-weight: bolder;
}

.loading-text {
    margin: 0;
    left: 50%;
    display: block;
    position: absolute;
    color: #57aec7;
    font-size: 25px;
    font-weight: bolder;
}

.loading-wave-status {
    margin-top: -15px;
    margin-left: -25px;
    left: 50%;
    display: block;
    position: absolute;
    color: #57aec7;
    font-size: 25px;
    font-weight: bolder;
}

.loading-wave-status-in-progress {
    margin-top: 5px;
    margin-left: -34px;
    left: 50%;
    display: block;
    position: absolute;
    color: #57aec7;
    font-size: 25px;
    font-weight: bolder;
}

.display-flex {
    display: flex;
}

.display-block {
    display: block;
}

.project-name {
    font-size: 20px;
    font-weight: bolder;
}

.is-urgent {
    color: red;
}

.issue-additional-text {
    font-size: 12px;
}

.data-row {
    display: flex;
    align-items: center;
}

.data-row-assignee {
    white-space: nowrap;
}

.project-tasks-unassigned {
    color: red;
}

.project-tasks-in-progress {
    color: orange;
}

.project-tasks-todo {
    color: darkblue;
}

.project-tasks-paused {
    color: darkslateblue;
}

.project-tasks-done {
    color: green;
}

.project-tasks-comparisons-count {
    color: darkslateblue;
}

.tasks-info {
    font-size: 13px;
    font-weight: bolder;
}

.project-public {
    text-align: center;
}
.project-not-answered-cmp {
    text-align: center;
}

.project-configuration-dropdown .button-icon {
    color: var(--main-theme-color);
}

.dropdown-options .remove-last-wave {
    color: red;
}

.dropdown-options .remove-last-wave .button-icon {
    color: red;
}

.project-configuration-dropdown .dropdown-options .button-icon {
    color: black;
    align-items: center;
}

.single-project-box-disabled {
    opacity: 0.3;
    pointer-events: none;
}

.single-project-box-disabled-message {
    margin: 0;
    padding: 5px 25px;
    border-radius: 20px;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    display: block;
    position: absolute;
    color: red;
    font-size: 25px;
    font-weight: bolder;
    background-color: #d9d9d9;
    z-index: 1;
    opacity: 0.8;
}

.single-project-box .project-accordion-toggle {
    color: black;
    opacity: 0.17;
    box-shadow: none !important;
    padding: 0;
    margin-left: 20px;
}

.disabled-configuration-link {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
}

.select-validation-error {
    font-size: 12px;
    color: rgb(244, 67, 54);
}

.task-name {
    font-size: 16px;
    font-weight: bolder;
}

.task-info-data {
    font-size: 12px;
    font-weight: bolder;
    max-width: 120px;
}

.task-info {
    font-size: 12px;
}

.single-project-task-box .task-progress-bar {
    height: 20px;
    margin-left: 10px;
}

.accordion .card-header {
    padding: 0;
    background-color: transparent;
    border-bottom: none;
}

.accordion .card {
    border: none;
    background-color: var(--body-background-color);
}

.task-delete-button {
    color: red;
    margin-left: 40px;
}

.task-delete-button:hover {
    color: darkred;
    cursor: pointer;
}

.task-blank-button {
    margin-left: 65px;
}

.task-pause-button {
    color: blue;
    margin-left: 40px;
}

.task-pause-button:hover {
    color: darkblue;
    cursor: pointer;
}

.task-leave-button {
    color: orange;
    margin-left: 40px;
}

.task-leave-button:hover {
    color: darkorange;
    cursor: pointer;
}

.task-resume-button {
    color: green;
    margin-left: 40px;
}

.task-resume-button:hover {
    color: darkgreen;
    cursor: pointer;
}

.task-progress-percent {
    font-size: 12px;
    font-weight: bolder;
    max-width: 120px;
    margin-left: 5px;
}

.task-progress-bar .progress-bar {
    font-weight: 1000;
}

.task-progress-bar .progress-bar.bg-info {
    background: repeating-linear-gradient(
        45deg,
        #606dbc,
        #606dbc 10px,
        #465298 10px,
        #465298 20px
    );
}

.project-results-wrapper .header-wrapper {
    /*TODO: question if its ok? default: space-between  */
    /*justify-content: end;*/
}

.project-results-wrapper .header-wrapper .project-results-search-box-wrapper {
    justify-content: end;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.project-results-wrapper .header-wrapper .btn {
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.project-results-wrapper .search-box {
    margin: unset;
}

.project-results-wrapper .search-box .search-icon {
    top: unset;
}

.project-results-body {
    display: grid;
    grid-template-columns: 11% 10% 3% 25% 3% 37% 11%;
    position: relative;
    top: 32px;
    /*margin: auto auto ;*/
}

.project-results-body > hr {
    width: 100%;
    display: flex;
    grid-column-start: 1;
    grid-column-end: 8;
    margin-top: 20px;
    margin-bottom: 20px;
}

.project-results-body .manager-answer-buttons-wrapper {
    grid-column-start: 2;
    grid-column-end: 3;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 16px 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.project-results-body .comparison-info-wrapper {
    grid-column-start: 4;
    grid-column-end: 4;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 16px 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.project-results-body .comparison-info-wrapper div {
    width: 100%;
}

.project-results-body .comparison-images-wrapper {
    grid-column-start: 6;
    grid-column-end: 6;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 16px 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /*max-height: 221px;*/
}

.project-results-body .comparison-images-wrapper img {
    max-width: 45%;
    max-height: 188px;
}

.project-results-body .manager-answer-buttons-wrapper {
    margin-top: auto;
    margin-bottom: auto;
}

.project-results-body .manager-answer-buttons-wrapper .btn {
    width: 100%;
    text-align: center;
}

.project-results-body .manager-answer-buttons-wrapper hr {
    width: 100%;
}

.project-results-wrapper .search-box input {
    width: 170px !important;
}

.project-results-wrapper .search-box.wider-search-box-input input {
    width: 180px !important;
}
.project-results-wrapper .search-box.wider-search-box-input input {
    width: 180px !important;
}
.search-icon {
    color: lightgray;
}
.project-results-algorithm-answer-skipped {
    color: darkslategray;
    font-weight: bold;
}
.project-results-algorithm-answer-true {
    color: darkgreen;
    font-weight: bold;
}
.project-results-algorithm-answer-false {
    color: darkred;
    font-weight: bold;
}
.project-results-spinner {
    color: #57aec7;
    font-size: 15px;
    font-weight: bolder;
    -webkit-animation: spin infinite 5s linear;
            animation: spin infinite 5s linear;
    margin: 10px;

    /*You can increase or decrease the timer (5s) to
     increase or decrease the speed of the spinner*/
}

@-webkit-keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.project-results-spiner-text {
    color: grey;
    font-size: 15px;
    font-weight: bolder;
}

.epack-select {
    margin-left: auto;
    margin-right: 2px;
}

.epack-select button {
    border-radius: 0 4px 4px 0;
    padding-left: 20px;
    padding-right: 20px;
}

.epack-select input {
    width: 250px !important;
    border-radius: 4px 0 0 4px !important;
    padding-left: 25px;
}

.epack-select .search-icon {
    color: lightgrey;
    position: relative;
    right: -19px;
    top: 1px;
    z-index: 9999;
}

.project-user-stats {
    margin-top: 2%;
}

.project-user-stats-values {
    font-size: 28px;
    background: var(--body-background-color);
    border-radius: 5px;
    max-width: 40%;
    margin: auto;
}

.project-user-stats {
    margin-top: 1%;
}

.project-user-stats-values {
    font-size: 15px;
    background: var(--body-background-color);
    border-radius: 5px;
    max-width: 40%;
    margin: auto;
}
.project-user-stats-values-danger {
    font-size: 15px;
    background: #e79393;
    border-radius: 5px;
    max-width: 40%;
    margin: auto;
}

.project-user-stats-values-warning {
    font-size: 15px;
    background: #e7c593;
    border-radius: 5px;
    max-width: 40%;
    margin: auto;
}


.comparisons-count-icon {
    color: darkgreen;
    margin-right: 10px;
}
.comparisons-failed-count-icon {
    color: darkorange;
    margin-right: 10px;
}
.comparisons-not-sure-count-icon {
    color: #960820;
    margin-right: 10px;
}
.comparisons-changed-count-icon {
    color: darkblue;
    margin-right: 10px;
}
.wave-in-progress-icon {
    color: darkred;
    margin-right: 10px;
}
.comparisons-import-time-icon {
    color: deeppink;
    margin-right: 10px;
}
.tasks-creation-icon {
    color: #026464;
    margin-right: 10px;
}
.comparisons-algorithm-compare-icon {
    color: indianred;
    margin-right: 10px;
}
.comparisons-copy-time-icon {
    color: #4b4b62;
    margin-right: 10px;
}

.user-form-wrapper .row,
.project-form-wrapper .row {
    margin: inherit;
}

.user-form-wrapper .checkbox-feedback {
    position: absolute;
    left: 90px;
}

.user-form-wrapper,
.project-form-wrapper {
    display: grid;
}

.user-form-wrapper form,
.project-form-wrapper form {
    justify-self: center;
    width: 50%;
    background: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    margin-top: 10px;
}

.user-form-wrapper form label {
    text-align: left;
}

.create-form-submit-btn {
    display: grid;
    justify-content: end;
}

.permission-checkbox-wrapper {
    display: flex;
}

.project-form-wrapper .row {
    margin: inherit;
}

.project-form-wrapper form .form-group:last-of-type {
    margin-bottom: 0 !important;
}

.user-form-wrapper form .form-group:last-of-type {
    margin-bottom: 0 !important;
}

#isAuditor:checked {
    background-color: green !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
    background: linear-gradient(rgb(109, 196, 215), var(--main-theme-color));
    border: none;
}

.single-task-card {
    min-height: 180px;
    /*width: 26vw;*/
    display: grid;
    grid-template-columns: repeat(auto-fill, 230px);
    grid-gap: 1rem;
    justify-content: space-between;
    padding: 1rem;
    margin-left: 3rem;
}

.single-task-card-info {
    font-size: 10px;
}

.single-task-take-it-btn {
    display: flex;
    justify-content: center;
}

/* Card title takes the same amount of space when title consists of only 1 row. */
.single-task-display-name {
    min-height: 2.8em;
}

.single-task-display-name p {
    margin-bottom: 0;
}
/* Card background white - workaround. */
.task-card {
    background-color: white;
}

:root {
    --dark: #1b1e24;
    --translate-scale: 2.2;
    /* Enables progress manipulation with JS | TaskProgressBar() */
    --progress-bar-completion: 0%;
}

.comparison-wrapper {
    display: grid;
    grid-template-columns: 11% 24% auto 51% 11%;
    position: relative;
    top: 32px;
    margin: auto auto 64px;
    align-content: start;
}

.comparison-wrapper .reference-wrapper {
    position: fixed;
    left: 11%;
    width: 24%;
    grid-column-start: 2;
    grid-column-end: 2;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 16px 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.comparison-wrapper .gap-wrapper {
    grid-column-start: 3;
    grid-column-end: 3;
}

.comparison-wrapper .parsed-img-wrapper {
    grid-column-start: 4;
    grid-column-end: 4;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    min-height: 100px;
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: 32% 32% 32%;
    grid-gap: 2%;
    gap: 2%;
    padding: 20px 2%;

    position: fixed;
    width: 51%;
    right: 11%;
    overflow-y: scroll;
    overflow-x: hidden;
    flex-direction: row;
    align-content: flex-start;

    top: 160px;
    bottom: 30px;
    /* 100%  - top offset - bottom offset*/
    height: calc(100% - 160px - 30px);
}

.header-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: space-between;
}

.header-wrapper div {
    width: 100%;
    align-items: center;
    display: inherit;
}

.header-wrapper div:nth-child(1) {
    justify-content: left;
}

.header-wrapper div:nth-child(2) {
    justify-content: center;
}

.header-wrapper div:nth-child(3) {
    justify-content: right;
}

.header-wrapper div .task-progress-bar-wrapper {
    width: 82%;
    height: 40%;
    border-radius: 16px;
    border: solid 1px #d4d4d4;
}

.header-wrapper div .task-progress-bar {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
    background: #5fb230
        linear-gradient(
            90deg,
            #5fb230 0%,
            #5fb230 0%,
            #f0f0f0 0%,
            #f0f0f0 100%
        );
    background: #5fb230
        linear-gradient(
            90deg,
            #5fb230 0%,
            #5fb230 var(--progress-bar-completion),
            #f0f0f0 var(--progress-bar-completion),
            #f0f0f0 100%
        );
}

.header-wrapper div .info-buttons {
    justify-content: right;
    width: 100%;
}

.undo-button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 4px;
    background-image: linear-gradient(to right, #70b9da 0%, #008dc2 100%);
}
.leave-task-button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 4px;
    background-image: linear-gradient(to right, #b67174 0%, #c2002d 100%);
}

.info-buttons .inverted-button {
    border-radius: 4px;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #1b1e24;
    color: var(--dark);
    background-image: none;
}

.bottom-grid-buttons .info-buttons .inverted-button {
    padding: 17px;
    box-shadow: 0px 0px 1px 0px gray;
}

.info-buttons .inverted-button:hover {
    box-shadow: 0 1px 4px -1px #333;
    transition: all 0.1s ease-in-out;
}

.info-buttons .inverted-button.disabled:hover {
    box-shadow: 0px 0px 1px 0px gray;
    transition: unset;
}

.info-buttons .inverted-button svg {
    color: #008dc2;
}

.header-wrapper .btn-primary {
    padding: 11px 17px;
    margin: 0 5px;
    height: 50%;
}

.global-header-override {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 64px;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 3px;
}

.global-header-override .project-header,
.global-header-override .shop-header {
    width: 100%;
    display: inherit;
    justify-content: center;
}

.global-header-override .project-header {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
    color: #1b1e24;
    color: var(--dark);
}

.global-header-override .shop-header {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #1b1e24;
    color: var(--dark);
}

.reference-img {
    max-width: 100%;
    max-height: 60vh;

    cursor: pointer;
}

.reference-header {
    font-family: Lato;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #1b1e24;
    color: var(--dark);
    width: 100%;
}

.grid-item {
    margin-bottom: 16px;
    margin-left: unset;
    margin-right: unset;
    border-radius: 4px;
    border: solid 1px #f0f0f0;
    background-color: #ffffff;
    display: inherit;
    justify-content: center;
    max-height: 180px;
    z-index: 1;
    width: 100%;
}

.parsed-img-wrapper:last-child:not("bottom-grid-buttons") {
    margin-left: 0;
}

.grid-item img {
    max-width: 100%;
    max-height: 164px;
    padding: 12px;
}

.grid-items-wrapper {
    width: 100%;
    display: grid;
    grid-auto-columns: auto;
    grid-template-columns: repeat(auto-fill, minmax(100px, 250px));
    justify-content: space-between;
    min-height: calc(100% - 100px);
}

.top-grid-buttons,
.bottom-grid-buttons {
    grid-column-start: 1;
    grid-column-end: 4;
    margin-bottom: 23px;
    display: inherit;
    justify-content: right;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.comparison-wrapper .custom-checkbox .custom-control-label {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1b1e24;
    color: var(--dark);
    padding-left: 11px;
}

.comparison-wrapper .custom-checkbox .custom-control-label::before {
    border-radius: 2px;
    border: solid 1px #8a8f97;
    background: none;
    z-index: -1;
    top: 1px;
    width: 18px;
    height: 18px;
}

.comparison-wrapper
    .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::after {
    background-image: none;
    background-color: #008dc2;
    z-index: -1;
    border-radius: 1px;
    width: 12px;
    height: 12px;
    margin: 3px;
    top: 1px;
}

.bottom-grid-buttons div {
    display: inherit;
    grid-template-columns: auto auto;
    max-height: 32px;
}

.bottom-grid-buttons div .btn-primary {
    margin: 0 5px;
    padding-left: 17px;
    padding-right: 17px;
}

.bottom-grid-buttons .warning-button svg {
    color: #e7bb00;
}

.bottom-grid-buttons .danger-button svg {
    color: darkred;
}

.next-button.disabled {
    border-radius: 4px;
    background-color: #f0f0f0;
    cursor: default;
}

.bottom-grid-buttons .next-button.disabled svg {
    color: grey;
}

.bottom-grid-buttons .next-button svg {
    color: #5fb230;
}

.all-next-button.disabled {
    border-radius: 4px;
    background-color: #f0f0f0;
    cursor: default;
}

.bottom-grid-buttons .all-next-button.disabled svg {
    color: grey;
}

.bottom-grid-buttons .all-next-button svg {
    color: #0c8dfd;
}

.grid-item-clone {
    align-content: center;
    display: grid;
    position: absolute;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
    border: solid 1px #f0f0f0;
    background-color: #ffffff;
    transform: scale(2.2);
    transform: scale(var(--translate-scale));
    z-index: 2;
}

.grid-item-clone.active-grid-item,
.parsed-img-wrapper .grid-item.active-grid-item,
.parsed-img-wrapper .grid-item.active-grid-item:hover {
    border: solid 2px #008dc2;
    margin-bottom: 14px;
}

.grid-item-wrapper {
    display: inherit;
}

.bottom-grid-buttons {
    margin-bottom: unset;
    margin-top: auto;

    width: -webkit-fit-content;

    width: fit-content;
    width: -moz-fit-content;
    margin-left: auto;
}

.main {
    min-height: 600px;
    margin: 0 auto;
    width: auto;
    max-width: 460px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.path {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    -webkit-animation: dash 3s alternate infinite;
            animation: dash 3s alternate infinite;
}

.fundo {
    -webkit-animation: scales 2.25s alternate infinite;
            animation: scales 2.25s alternate infinite;
    transform-origin: center;
}

.pao-baixo {
    -webkit-animation: rotatepao 10.5s cubic-bezier(0.1, 0.49, 0.41, 0.97) infinite;
            animation: rotatepao 10.5s cubic-bezier(0.1, 0.49, 0.41, 0.97) infinite;
    transform-origin: center;
}

.pao-cima {
    -webkit-animation: rotatepao 5.25s 0.75s cubic-bezier(0.1, 0.49, 0.41, 0.97)
        infinite;
            animation: rotatepao 5.25s 0.75s cubic-bezier(0.1, 0.49, 0.41, 0.97)
        infinite;
    transform-origin: center;
}

.olhos {
    -webkit-animation: olhos 1.5s alternate infinite;
            animation: olhos 1.5s alternate infinite;
    transform-origin: center;
}

.left-sparks {
    -webkit-animation: left-sparks 3s alternate infinite;
            animation: left-sparks 3s alternate infinite;
    transform-origin: 150px 156px;
}

.right-sparks {
    -webkit-animation: left-sparks 3s alternate infinite;
            animation: left-sparks 3s alternate infinite;
    transform-origin: 310px 150px;
}

.olhos {
    -webkit-animation: olhos 1.5s alternate infinite;
            animation: olhos 1.5s alternate infinite;
    transform-origin: center;
}

@-webkit-keyframes scales {
    from {
        transform: scale(0.9);
    }
    to {
        transform: scale(1);
    }
}

@keyframes scales {
    from {
        transform: scale(0.9);
    }
    to {
        transform: scale(1);
    }
}

@-webkit-keyframes rotatepao {
    0% {
        transform: rotate(0deg);
    }
    50%,
    60% {
        transform: rotate(-20deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes rotatepao {
    0% {
        transform: rotate(0deg);
    }
    50%,
    60% {
        transform: rotate(-20deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@-webkit-keyframes olhos {
    0% {
        transform: rotateX(0deg);
    }
    100% {
        transform: rotateX(30deg);
    }
}

@keyframes olhos {
    0% {
        transform: rotateX(0deg);
    }
    100% {
        transform: rotateX(30deg);
    }
}

@-webkit-keyframes left-sparks {
    0% {
        opacity: 0;
    }
}

@keyframes left-sparks {
    0% {
        opacity: 0;
    }
}

@-webkit-keyframes dash {
    0%,
    30% {
        fill: #4b4b62;
        stroke-dashoffset: 0;
    }
    80%,
    100% {
        fill: transparent;
        stroke-dashoffset: -200;
    }
}

@keyframes dash {
    0%,
    30% {
        fill: #4b4b62;
        stroke-dashoffset: 0;
    }
    80%,
    100% {
        fill: transparent;
        stroke-dashoffset: -200;
    }
}

.main-error-page {
    min-height: 600px;
    margin: 0px auto;
    width: auto;
    max-width: 560px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.error-title {
    max-width: 529px;
    font-family: Roboto;
    font-size: 38px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b62;
    margin-bottom: 16px;
}

.error-subtitle {
    max-width: 568px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: center;
    color: #4b4b62;
    margin-bottom: 24px;
}

.pao-atras {
    -webkit-animation: leftright 1s alternate infinite;
            animation: leftright 1s alternate infinite;
    transform-origin: center;
}

.pao-frente {
    -webkit-animation: leftright 1s 0.3s alternate infinite;
            animation: leftright 1s 0.3s alternate infinite;
    transform-origin: center;
}

.olho-esq {
    -webkit-animation: sad 2s alternate infinite;
            animation: sad 2s alternate infinite;
    transform-origin: center;
}

.olho-dir {
    -webkit-animation: sad 2s alternate infinite;
            animation: sad 2s alternate infinite;
    transform-origin: center;
}

.boca {
    -webkit-animation: sad 2s alternate infinite;
            animation: sad 2s alternate infinite;
    transform-origin: center;
}

.raios {
    -webkit-animation: flicker-4 4s linear infinite both;
    animation: flicker-4 4s linear infinite both;
}

.tomada {
    -webkit-animation: vibrate-1 3s linear infinite both;
    animation: vibrate-1 3s linear infinite both;
}

.fio-500 {
    -webkit-animation: vibrate-1 3s linear infinite both;
    animation: vibrate-1 3s linear infinite both;
}

.fio {
    -webkit-animation: vibrate-1 3s linear infinite both;
    animation: vibrate-1 3s linear infinite both;
}

@-webkit-keyframes scales {
    from {
        transform: scale(0.98);
    }
    to {
        transform: scale(1);
    }
}

@keyframes scales {
    from {
        transform: scale(0.98);
    }
    to {
        transform: scale(1);
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 14:58:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flicker-4
 * ----------------------------------------
 */
@-webkit-keyframes flicker-4 {
    0%,
    100% {
        opacity: 1;
    }
    31.98% {
        opacity: 1;
    }
    32% {
        opacity: 0;
    }
    32.8% {
        opacity: 0;
    }
    32.82% {
        opacity: 1;
    }
    34.98% {
        opacity: 1;
    }
    35% {
        opacity: 0;
    }
    35.7% {
        opacity: 0;
    }
    35.72% {
        opacity: 1;
    }
    36.98% {
        opacity: 1;
    }
    37% {
        opacity: 0;
    }
    37.6% {
        opacity: 0;
    }
    37.62% {
        opacity: 1;
    }
    67.98% {
        opacity: 1;
    }
    68% {
        opacity: 0;
    }
    68.4% {
        opacity: 0;
    }
    68.42% {
        opacity: 1;
    }
    95.98% {
        opacity: 1;
    }
    96% {
        opacity: 0;
    }
    96.7% {
        opacity: 0;
    }
    96.72% {
        opacity: 1;
    }
    98.98% {
        opacity: 1;
    }
    99% {
        opacity: 0;
    }
    99.6% {
        opacity: 0;
    }
    99.62% {
        opacity: 1;
    }
}

@keyframes flicker-4 {
    0%,
    100% {
        opacity: 1;
    }
    31.98% {
        opacity: 1;
    }
    32% {
        opacity: 0;
    }
    32.8% {
        opacity: 0;
    }
    32.82% {
        opacity: 1;
    }
    34.98% {
        opacity: 1;
    }
    35% {
        opacity: 0;
    }
    35.7% {
        opacity: 0;
    }
    35.72% {
        opacity: 1;
    }
    36.98% {
        opacity: 1;
    }
    37% {
        opacity: 0;
    }
    37.6% {
        opacity: 0;
    }
    37.62% {
        opacity: 1;
    }
    67.98% {
        opacity: 1;
    }
    68% {
        opacity: 0;
    }
    68.4% {
        opacity: 0;
    }
    68.42% {
        opacity: 1;
    }
    95.98% {
        opacity: 1;
    }
    96% {
        opacity: 0;
    }
    96.7% {
        opacity: 0;
    }
    96.72% {
        opacity: 1;
    }
    98.98% {
        opacity: 1;
    }
    99% {
        opacity: 0;
    }
    99.6% {
        opacity: 0;
    }
    99.62% {
        opacity: 1;
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 15:17:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-1 {
    0% {
        transform: translate(0);
    }
    20% {
        transform: translate(-2px, 2px);
    }
    40% {
        transform: translate(-2px, -2px);
    }
    60% {
        transform: translate(2px, 2px);
    }
    80% {
        transform: translate(2px, -2px);
    }
    100% {
        transform: translate(0);
    }
}

@keyframes vibrate-1 {
    0% {
        transform: translate(0);
    }
    20% {
        transform: translate(-2px, 2px);
    }
    40% {
        transform: translate(-2px, -2px);
    }
    60% {
        transform: translate(2px, 2px);
    }
    80% {
        transform: translate(2px, -2px);
    }
    100% {
        transform: translate(0);
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 15:42:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-ver-right
 * ----------------------------------------
 */
@-webkit-keyframes wobble-ver-right {
    0%,
    100% {
        transform: translateY(0) rotate(0);
        transform-origin: 50% 50%;
    }
    15% {
        transform: translateY(-30px) rotate(6deg);
    }
    30% {
        transform: translateY(15px) rotate(-6deg);
    }
    45% {
        transform: translateY(-15px) rotate(3.6deg);
    }
    60% {
        transform: translateY(9px) rotate(-2.4deg);
    }
    75% {
        transform: translateY(-6px) rotate(1.2deg);
    }
}

@-webkit-keyframes sad {
    0% {
        transform: rotateX(0deg) rotateY(0deg);
    }
    100% {
        transform: rotateX(10deg) rotateY(5deg);
    }
}

@keyframes sad {
    0% {
        transform: rotateX(0deg) rotateY(0deg);
    }
    100% {
        transform: rotateX(10deg) rotateY(5deg);
    }
}

@-webkit-keyframes leftright {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(-15deg);
    }
}

@keyframes leftright {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(-15deg);
    }
}

.single-issue-box div {
    display: flex;
}

.single-issue-box .issue-name {
    font-size: 20px;
    font-weight: bolder;
}

.single-issue-box .issue-description .description-collapsed {
    display: block !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    color: #404548 !important;
}

.single-issue-box .issue-description {
    padding-left: 0;
    padding-top: 5px;
}

.single-issue-box .freeze-badge {
    width: 6%;
    justify-content: end;
}

.single-issue-box .issue-accordion {
    width: 70%;
}

.single-issue-box .issue-accordion .card-body {
    padding-top: 5px;
    padding-left: 0;
    padding-bottom: 0;
}

.single-issue-box .issue-accordion-toggle {
    color: black;
    opacity: 0.17;
    box-shadow: none !important;
    height: 17px;
}

.single-issue-box .dropdown-toggle-wrapper {
    width: 3%;
}

.single-issue-box .dropdown-toggle-wrapper .btn {
    padding-top: 3px;
}

.single-issue-box .resolve-dropdown-wrapper {
    width: 27%;
}

.single-issue-box .badge-danger {
    height: 17px;
}

.single-issue-box .hidden {
    display: none !important;
}

.single-issue-box .show div,
.single-issue-box .collapsing div {
    display: block;
}

.second-row {
    padding: 10px 0;
}

.issues-count-badge {
    margin-left: 5px;
}

.badge-pill {
    padding: 0.2rem 0.5rem 0.205rem 0.5rem;
    display: inline;
    vertical-align: middle;
}

.collapse.show ~ .issue-description,
.collapsing ~ .issue-description {
    display: none !important;
}

.collapse:not(.show) ~ .issue-description {
    display: unset !important;
}

.issue-body-button {
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    margin-right: auto;
}

.freeze-task-button,
.freeze-task-button:hover {
    background-image: linear-gradient(#dc3545, #ec6161);
    color: white !important;
    border: unset !important;
}

.freeze-task-button svg {
    color: white !important;
}

.task-badge {
    cursor: auto !important;
    cursor: initial !important;
    margin-top: 1px;
}

.task-badge:hover,
.task-badge:focus,
.task-badge:active {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    box-shadow: none;
}
.task-badge.badge {
    padding: 8px;
    font-size: 12px;
}

.issue-status-badge {
    font-size: 10px;
    margin-top: 1px;
    margin-right: auto;
    color: #fff;
    box-shadow: none;
}

.issue-status-badge.resolved {
    background-color: #dc3545;
    border-color: #dc3545;
}

.issue-status-badge.open {
    background-color: #15ce2a;
    border-color: #15ce2a;
}

.auditor-global-stats {
    margin-top: 15%;
}

.auditor-global-stats-values {
    font-size: 28px;
    background: var(--body-background-color);
    border-radius: 5px;
    max-width: 40%;
    margin: auto;
}

.auditor-global-stats {
    margin-top: 15%;
}

.auditor-global-stats-values {
    font-size: 28px;
    background: var(--body-background-color);
    border-radius: 5px;
    max-width: 40%;
    margin: auto;
}

.auditor-global-stats {
    margin-top: 15%;
}

.auditor-global-stats-values {
    font-size: 28px;
    background: var(--body-background-color);
    border-radius: 5px;
    max-width: 40%;
    margin: auto;
}

.detailed-stats-button {
    float: right;
}
.detailed-sort-button {
    padding-right: 10px;
    float: left;
}

