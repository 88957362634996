body {
    background-color: var(--body-background-color);
    font-family: "Lato", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.main-container {
    margin: 50px auto;
    width: 960px;
}

.container-white {
    background: white;
    border-radius: 4px;
    padding: 35px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
    margin-bottom: 10px;
}

.white-box {
    background: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 35px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
    margin-bottom: 10px;
}

.urgent-box {
    background: lightgoldenrodyellow;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 35px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
    margin-bottom: 10px;
}

.white-box-list {
    padding: 10px 35px;
}

.white-box-list-nested {
    margin-left: 50px;
    margin-right: 50px;
}

.data-row {
    display: flex;
    align-items: center;
}

/* For red asterisk if required*/
label.required::before {
    content: "* ";
    color: #d72c2c;
}

/* All custom checkboxes with gradient background */
.custom-control-input:checked ~ .custom-control-label::before {
    background: linear-gradient(rgb(109, 196, 215), var(--main-theme-color));
    border: none;
}

/* SweetAlert modal styles */
.swal2-shown.swal2-confirm {
    background-image: linear-gradient(
        rgb(109, 196, 215),
        var(--main-theme-color)
    );
    border: 0;
}

.swal2-shown .swal2-confirm:hover {
    box-shadow: 0 1px 4px -1px #333;
    transition: all 0.1s ease-in-out;
}

.swal2-shown [type="button"] {
    padding: 7px 19px;
    border-radius: 2px;
    border: 0;
}

.search-box input::-webkit-outer-spin-button,
.search-box input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

.search-box input[type="number"] {
    -moz-appearance: textfield !important;
}
