.user-control-buttons {
    display: flex;
}

.user-name {
    font-size: 16px;
    font-weight: bolder;
}

.user-email,
.user-create-date {
    font-size: 12px;
}
