.single-issue-box div {
    display: flex;
}

.single-issue-box .issue-name {
    font-size: 20px;
    font-weight: bolder;
}

.single-issue-box .issue-description .description-collapsed {
    display: block !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    color: #404548 !important;
}

.single-issue-box .issue-description {
    padding-left: 0;
    padding-top: 5px;
}

.single-issue-box .freeze-badge {
    width: 6%;
    justify-content: end;
}

.single-issue-box .issue-accordion {
    width: 70%;
}

.single-issue-box .issue-accordion .card-body {
    padding-top: 5px;
    padding-left: 0;
    padding-bottom: 0;
}

.single-issue-box .issue-accordion-toggle {
    color: black;
    opacity: 0.17;
    box-shadow: none !important;
    height: 17px;
}

.single-issue-box .dropdown-toggle-wrapper {
    width: 3%;
}

.single-issue-box .dropdown-toggle-wrapper .btn {
    padding-top: 3px;
}

.single-issue-box .resolve-dropdown-wrapper {
    width: 27%;
}

.single-issue-box .badge-danger {
    height: 17px;
}

.single-issue-box .hidden {
    display: none !important;
}

.single-issue-box .show div,
.single-issue-box .collapsing div {
    display: block;
}

.second-row {
    padding: 10px 0;
}

.issues-count-badge {
    margin-left: 5px;
}

.badge-pill {
    padding: 0.2rem 0.5rem 0.205rem 0.5rem;
    display: inline;
    vertical-align: middle;
}

.collapse.show ~ .issue-description,
.collapsing ~ .issue-description {
    display: none !important;
}

.collapse:not(.show) ~ .issue-description {
    display: unset !important;
}

.issue-body-button {
    height: min-content;
    margin-right: auto;
}

.freeze-task-button,
.freeze-task-button:hover {
    background-image: linear-gradient(#dc3545, #ec6161);
    color: white !important;
    border: unset !important;
}

.freeze-task-button svg {
    color: white !important;
}

.task-badge {
    cursor: initial !important;
    margin-top: 1px;
}

.task-badge:hover,
.task-badge:focus,
.task-badge:active {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    box-shadow: none;
}
.task-badge.badge {
    padding: 8px;
    font-size: 12px;
}

.issue-status-badge {
    font-size: 10px;
    margin-top: 1px;
    margin-right: auto;
    color: #fff;
    box-shadow: none;
}

.issue-status-badge.resolved {
    background-color: #dc3545;
    border-color: #dc3545;
}

.issue-status-badge.open {
    background-color: #15ce2a;
    border-color: #15ce2a;
}
