.epack-select {
    margin-left: auto;
    margin-right: 2px;
}

.epack-select button {
    border-radius: 0 4px 4px 0;
    padding-left: 20px;
    padding-right: 20px;
}

.epack-select input {
    width: 250px !important;
    border-radius: 4px 0 0 4px !important;
    padding-left: 25px;
}

.epack-select .search-icon {
    color: lightgrey;
    position: relative;
    right: -19px;
    top: 1px;
    z-index: 9999;
}
