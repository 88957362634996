.project-user-stats {
    margin-top: 2%;
}

.project-user-stats-values {
    font-size: 28px;
    background: var(--body-background-color);
    border-radius: 5px;
    max-width: 40%;
    margin: auto;
}
