.task-name {
    font-size: 16px;
    font-weight: bolder;
}

.task-info-data {
    font-size: 12px;
    font-weight: bolder;
    max-width: 120px;
}

.task-info {
    font-size: 12px;
}

.single-project-task-box .task-progress-bar {
    height: 20px;
    margin-left: 10px;
}

.accordion .card-header {
    padding: 0;
    background-color: transparent;
    border-bottom: none;
}

.accordion .card {
    border: none;
    background-color: var(--body-background-color);
}

.task-delete-button {
    color: red;
    margin-left: 40px;
}

.task-delete-button:hover {
    color: darkred;
    cursor: pointer;
}

.task-blank-button {
    margin-left: 65px;
}

.task-pause-button {
    color: blue;
    margin-left: 40px;
}

.task-pause-button:hover {
    color: darkblue;
    cursor: pointer;
}

.task-leave-button {
    color: orange;
    margin-left: 40px;
}

.task-leave-button:hover {
    color: darkorange;
    cursor: pointer;
}

.task-resume-button {
    color: green;
    margin-left: 40px;
}

.task-resume-button:hover {
    color: darkgreen;
    cursor: pointer;
}

.task-progress-percent {
    font-size: 12px;
    font-weight: bolder;
    max-width: 120px;
    margin-left: 5px;
}

.task-progress-bar .progress-bar {
    font-weight: 1000;
}

.task-progress-bar .progress-bar.bg-info {
    background: repeating-linear-gradient(
        45deg,
        #606dbc,
        #606dbc 10px,
        #465298 10px,
        #465298 20px
    );
}
