.auditor-global-stats {
    margin-top: 15%;
}

.auditor-global-stats-values {
    font-size: 28px;
    background: var(--body-background-color);
    border-radius: 5px;
    max-width: 40%;
    margin: auto;
}
