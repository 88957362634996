.user-form-wrapper form .form-group:last-of-type {
    margin-bottom: 0 !important;
}

#isAuditor:checked {
    background-color: green !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
    background: linear-gradient(rgb(109, 196, 215), var(--main-theme-color));
    border: none;
}
