.user-form-wrapper .row,
.project-form-wrapper .row {
    margin: inherit;
}

.user-form-wrapper .checkbox-feedback {
    position: absolute;
    left: 90px;
}

.user-form-wrapper,
.project-form-wrapper {
    display: grid;
}

.user-form-wrapper form,
.project-form-wrapper form {
    justify-self: center;
    width: 50%;
    background: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    margin-top: 10px;
}

.user-form-wrapper form label {
    text-align: left;
}

.create-form-submit-btn {
    display: grid;
    justify-content: end;
}

.permission-checkbox-wrapper {
    display: flex;
}
