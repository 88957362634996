.loading-text-centered {
    margin: 0;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    display: block;
    position: absolute;
    color: #57aec7;
    font-size: 25px;
    font-weight: bolder;
}

.loading-text {
    margin: 0;
    left: 50%;
    display: block;
    position: absolute;
    color: #57aec7;
    font-size: 25px;
    font-weight: bolder;
}

.loading-wave-status {
    margin-top: -15px;
    margin-left: -25px;
    left: 50%;
    display: block;
    position: absolute;
    color: #57aec7;
    font-size: 25px;
    font-weight: bolder;
}

.loading-wave-status-in-progress {
    margin-top: 5px;
    margin-left: -34px;
    left: 50%;
    display: block;
    position: absolute;
    color: #57aec7;
    font-size: 25px;
    font-weight: bolder;
}
