.project-results-wrapper .header-wrapper {
    /*TODO: question if its ok? default: space-between  */
    /*justify-content: end;*/
}

.project-results-wrapper .header-wrapper .project-results-search-box-wrapper {
    justify-content: end;
    width: fit-content;
}

.project-results-wrapper .header-wrapper .btn {
    margin-top: auto;
    margin-bottom: auto;
    width: fit-content;
}

.project-results-wrapper .search-box {
    margin: unset;
}

.project-results-wrapper .search-box .search-icon {
    top: unset;
}

.project-results-body {
    display: grid;
    grid-template-columns: 11% 10% 3% 25% 3% 37% 11%;
    position: relative;
    top: 32px;
    /*margin: auto auto ;*/
}

.project-results-body > hr {
    width: 100%;
    display: flex;
    grid-column-start: 1;
    grid-column-end: 8;
    margin-top: 20px;
    margin-bottom: 20px;
}

.project-results-body .manager-answer-buttons-wrapper {
    grid-column-start: 2;
    grid-column-end: 3;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    height: fit-content;
    padding: 16px 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.project-results-body .comparison-info-wrapper {
    grid-column-start: 4;
    grid-column-end: 4;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    height: fit-content;
    padding: 16px 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.project-results-body .comparison-info-wrapper div {
    width: 100%;
}

.project-results-body .comparison-images-wrapper {
    grid-column-start: 6;
    grid-column-end: 6;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    height: fit-content;
    padding: 16px 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /*max-height: 221px;*/
}

.project-results-body .comparison-images-wrapper img {
    max-width: 45%;
    max-height: 188px;
}

.project-results-body .manager-answer-buttons-wrapper {
    margin-top: auto;
    margin-bottom: auto;
}

.project-results-body .manager-answer-buttons-wrapper .btn {
    width: 100%;
    text-align: center;
}

.project-results-body .manager-answer-buttons-wrapper hr {
    width: 100%;
}

.project-results-wrapper .search-box input {
    width: 170px !important;
}

.project-results-wrapper .search-box.wider-search-box-input input {
    width: 180px !important;
}
.project-results-wrapper .search-box.wider-search-box-input input {
    width: 180px !important;
}
.search-icon {
    color: lightgray;
}
.project-results-algorithm-answer-skipped {
    color: darkslategray;
    font-weight: bold;
}
.project-results-algorithm-answer-true {
    color: darkgreen;
    font-weight: bold;
}
.project-results-algorithm-answer-false {
    color: darkred;
    font-weight: bold;
}
.project-results-spinner {
    color: #57aec7;
    font-size: 15px;
    font-weight: bolder;
    animation: spin infinite 5s linear;
    margin: 10px;

    /*You can increase or decrease the timer (5s) to
     increase or decrease the speed of the spinner*/
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.project-results-spiner-text {
    color: grey;
    font-size: 15px;
    font-weight: bolder;
}
