#header-global {
    height: 64px;
    display: flex;
    align-items: center;
}

.epack-logo {
    max-height: 64px;
    /*border: 1px solid red; !*TODO: For testing*!*/
}

.main-navbar ul.nav li {
    margin: 0 0 0 32px;
}
.main-navbar ul.nav li a {
    /*border: 1px solid red; !*TODO: For testing*!*/
    padding: 6px 0;
    font-size: 16px;
    color: #1b1e24;
    text-decoration: none;
    border-bottom: 2px solid #ffffff;
    transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out,
        border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

.main-navbar ul.nav li a:hover {
    border-bottom: 2px solid #e2e6ea;
}

.navbar-active {
    border-bottom: 2px solid var(--main-theme-color) !important;
}

.user-dropdown {
    margin-left: auto;
}
