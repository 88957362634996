.project-user-stats {
    margin-top: 1%;
}

.project-user-stats-values {
    font-size: 15px;
    background: var(--body-background-color);
    border-radius: 5px;
    max-width: 40%;
    margin: auto;
}
.project-user-stats-values-danger {
    font-size: 15px;
    background: #e79393;
    border-radius: 5px;
    max-width: 40%;
    margin: auto;
}

.project-user-stats-values-warning {
    font-size: 15px;
    background: #e7c593;
    border-radius: 5px;
    max-width: 40%;
    margin: auto;
}


.comparisons-count-icon {
    color: darkgreen;
    margin-right: 10px;
}
.comparisons-failed-count-icon {
    color: darkorange;
    margin-right: 10px;
}
.comparisons-not-sure-count-icon {
    color: #960820;
    margin-right: 10px;
}
.comparisons-changed-count-icon {
    color: darkblue;
    margin-right: 10px;
}
.wave-in-progress-icon {
    color: darkred;
    margin-right: 10px;
}
.comparisons-import-time-icon {
    color: deeppink;
    margin-right: 10px;
}
.tasks-creation-icon {
    color: #026464;
    margin-right: 10px;
}
.comparisons-algorithm-compare-icon {
    color: indianred;
    margin-right: 10px;
}
.comparisons-copy-time-icon {
    color: #4b4b62;
    margin-right: 10px;
}
